import { useCart } from 'components/Cart'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { toFriendlyKitId } from 'helpers/string'

const OrderConfirmation = () => {
  const { orderConfirmation } = useCart()

  if (orderConfirmation) {
    const { hasResponse, hasRefs, orderId, day2and8refLines } = orderConfirmation

    if (!hasResponse) {
      return (
        <>
          <GatsbySeo title="Order Confirmation" />

          <section className="section is-small">
            <div className="container is-max-desktop">
              <h1 className="title has-text-midnightBlue is-size-4-mobile">Order Confirmation</h1>

              <p className="subtitle has-text-midnightBlue is-size-6-mobile">
                Your order has been placed! We will email you shortly to confirm your order and provide additional
                information on taking your test.
              </p>
            </div>
          </section>
        </>
      )
    }

    return (
      <>
        <GatsbySeo title="Order Confirmation" />

        <section className="section is-small">
          <div className="container is-max-desktop">
            <h1 className="title has-text-midnightBlue is-size-4-mobile">Order Confirmation</h1>

            <p className="is-size-4 mb-4">
              <strong>Order ID: </strong>
              {toFriendlyKitId(orderId)}
            </p>

            <p className="has-text-midnightBlue">
              Your order has been placed! We will email you shortly to confirm successful payment, and update you when
              your kits are dispatched and processed.
            </p>

            {hasRefs && (
              <>
                <h3 className="title is-size-4 mt-4">Your Booking Reference Numbers</h3>

                <p className="mb-3">
                  Please make a note of these numbers or print this page. These booking numbers will also be in your
                  order confirmation email.
                </p>

                <ul>
                  {day2and8refLines.map((line, i) => (
                    <li key={i}>
                      {line.firstName} {line.lastName}:{' '}
                      <strong className="is-family-monospace">{line.day2and8ref}</strong>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </section>
      </>
    )
  } else {
    return <p className="has-text-centered">Loading...</p>
  }
}

export default OrderConfirmation
