import { parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import enGB from 'date-fns/locale/en-GB'

export const formatCurrency = (pennies: number) => {
  const showDecimals = pennies % 100 !== 0
  const digits = showDecimals ? 2 : 0

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })

  return formatter.format(pennies / 100)
}

export const formatDate = (dateString: string) =>
  format(utcToZonedTime(parseISO(dateString), 'Europe/London'), 'dd/MM/yy HH:mm zzz', {
    locale: enGB,
    timeZone: 'Europe/London',
  })

export const formatDateOnly = (dateString: string) => format(parseISO(dateString), 'dd/MM/yyyy')

export const toFriendlyKitId = (id) => {
  const trimmed = id.replace('Kit-', '').replace('Order-', '')

  return `${trimmed.slice(0, 4)}-${trimmed.slice(4)}`
}

export function capitalizeFirstLetter(string: string) {
  if (string.length === 0) return string

  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export const toFriendlyRecoveryCertificateId = (id: string) => id.replace(/(.{4})(.+)/, '$1-$2')
